$(function () {
    $('.main-slider').slick({
        autoplay: true,
        draggable: true,
        fade: true,
        speed: 1000,
        arrows: true,
        dots: true,
        slide: '.slide',
        prevArrow: '<a href="javascript:;" class="prev"></a>',
        nextArrow: '<a href="javascript:;" class="next"></a>'
    });

    $('.product-list').slick({
        autoplay: true,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        slide: '.product-box',
        prevArrow: '<a href="javascript:;" class="prev"></a>',
        nextArrow: '<a href="javascript:;" class="next"></a>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('section.products .next').click(function () {
        $('.product-list').slick('slickNext');
    });
    $('section.products .prev').click(function () {
        $('.product-list').slick('slickPrev');
    });

    $('.filters a').click(function (e) {
        e.preventDefault();
        $('.filters a').removeClass('active');
        $(this).addClass('active');

        var activeTab = $(this).attr('href');

        $('section.products .product-list').removeClass('active');
        $(activeTab).slick('unslick');
        $(activeTab).addClass('active');
        $(activeTab).slick({
            autoplay: true,
            draggable: true,
            fade: false,
            speed: 1000,
            arrows: false,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            slide: '.product-box',
            prevArrow: '<a href="javascript:;" class="prev"></a>',
            nextArrow: '<a href="javascript:;" class="next"></a>'
        });
    });

    $('.reference-slider').slick({
        autoplay: true,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('section.references .next').click(function () {
        $('.reference-slider').slick('slickNext');
    });
    $('section.references .prev').click(function () {
        $('.reference-slider').slick('slickPrev');
    });

    $('.menu-opener').click(function () {
       $('body').addClass('menu-opened');
    });
    $('.main-menu .closer').click(function () {
       $('body').removeClass('menu-opened');
    });


    $('.video-slider .slides').slick({
        autoplay: false,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.video-slider .next').click(function () {
        $('.video-slider .slides').slick('slickNext');
    });
    $('.video-slider .prev').click(function () {
        $('.video-slider .slides').slick('slickPrev');
    });

    $('.photo-slider .slides').slick({
        autoplay: false,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.photo-slider .next').click(function () {
        $('.photo-slider .slides').slick('slickNext');
    });
    $('.photo-slider .prev').click(function () {
        $('.photo-slider .slides').slick('slickPrev');
    });


    $('[data-countdown]').each(function () {
        var $this = $(this), finalDate = $(this).data('countdown');
        $this.countdown(finalDate, function (event) {
            $this.html(event.strftime('%D gün %H:%M:%S'));
        }).on('update.countdown', function (event) {
            $this.html(event.strftime('%D gün %H:%M:%S'));
        });
    });
});